<template>
  <div class="flex flex-col items-center calendar-width">
    <div class="go-date-picker-popover-header md:p-2 shadow-md calendar-width text-center">
      <div class="flex justify-between items-center ">
        <span class="go-date-picker-prev p-3 cursor-pointer text-main font-bold" :class="{disabled : isSameMonth}" @click="changeMonth(-1)">
          <img src="@/assets/image/arrLeft.png" alt="arrowLeft" />
        </span>
        <div class="flex items-center text-xs md:text-base mx-4" v-if="!loading">
          <img class="pr-2" src="@/assets/image/svg_calendar.svg" alt="calendar" />
          <span v-if="!isChangeMonth" class="
               cursor-pointer tracking-wider text-main font-bold x-2" @click="dayPanel = !dayPanel">
            {{ formatDate.year }}年{{ formatDate.month }}月{{
              formatDate.day
            }}日 (週{{ chineseDay }})</span>
          <span v-if="isChangeMonth" class="
               cursor-pointer tracking-wider text-main font-bold x-2">
            {{ formatDate.year }}年{{ formatDate.month }}月</span>
        </div>
        <span class="go-date-picker-next p-3 cursor-pointer text-main font-bold" @click="changeMonth(1)">
          <img src="@/assets/image/arrRight.png" alt="arrowRight" />
        </span>
      </div>

      <div class="go-date-picker-popover-content w-full py-2 text-xs md:text-base">
        <div class="go-date-picker-days flex flex-col items-center">
          <div class="go-date-picker-weeks flex text-center justify-center">
            <div class="
                go-date-picker-week-cell
                text-grayColor-light
                font-semibold
              " v-for="week in weeks" :key="week">
              {{ week }}
            </div>
          </div>
          <!-- :class="{unavaliableTime: getArrDifference.find((time) => time === i) -->
          <!-- {disabled: getLimitDays.find((date) => date !== getDays) } -->
          <!-- { disabled :newArray.date.find((date) => date !== getLimitDays) } -->
          <div>
            <div class="go-date-picker-days-row flex " v-for="(row, i) in getDays" :key="`${row}-${i}`" :class="{ noneShow : isShowRow(row)}">
              <div class="go-date-picker-days-cell rounded-md text-center cursor-pointer hover:bg-yellow-100  date-hover
              " :class="[dayClasses(cell) ]" v-for="(cell, j) in row" :key="`${cell}-${j}`" @click="onClickDay(cell)">
                {{ getDay(cell) }}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toMatrix } from "@/assets/filters/util.js";
import moment from "moment";

import {
  cloneDate,
  getCurrentMonthLastDay,
  getPrevMonthLastDay,
  getYearMonthDay,
} from "@/assets/filters/date.js";

export default {
  props: {
    canOrderTimes: {
      type: Array,
    },
    store: {
      type: Object,
    },
    openPanel: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      weeks: ["日", "一", "二", "三", "四", "五", "六"],
      dayPanel: false,
      limitDayArray: [],
      newArray: [],
    };
  },
  methods: {
    isShowRow(val) {
      let res = val.every(function (value) {
        return value.status == "prev";
      });
      return res;
    },
    changeMonth(value) {
      // const [, month] = getYearMonthDay(this.tempValue);
      // this.tempValue = new Date(
      //   cloneDate(this.tempValue).setMonth(month + value)
      // );
      this.$store.commit("CHANGEMONTHTEMP", value);
    },
    getDay(cell) {
      return cell.date.getDate();
    },
    dayClasses(cell) {
      return {
        prev: cell.status === "prev",
        next: cell.status === "next",
        avaliable: cell.status === "avaliable",
        active: this.isSameDay(cell.date, this.tempValue),
        today: this.isToday(cell.date),
        beforeToday: this.beforeToday(cell.date),
        anotherDate: this.anotherDate(cell.date),
        limitDay: this.limitDay(cell.date),
        unavaliableDate: this.unavaliableDate(cell.date),
        dayOff: this.isDayOff(cell.date),
      };
    },
    isDayOff(date) {
      const day = new Date(date).getDay();
      const a = Object.values(this.store.business_hours).find((obj, index) => {
        if (obj.length === 0 && index === day) {
          return index === day;
        }
      });
      return a;
    },
    anotherDate(data) {
      let d = new Date(data),
        m1 = d.getMonth(data) + 1,
        d1 = d.getDate(data),
        y1 = d.getFullYear(data);
      if (m1 < 10) {
        m1 = "0" + m1;
      }
      if (d1 < 10) {
        d1 = "0" + d1;
      }
      const OptionDate = y1 + "-" + m1 + "-" + d1;
      const result = this.canOrderTimes.find((time) => time === OptionDate);
      return result;
    },
    onClickDay(cell) {
      this.$store.commit("ISCHANGEMONTH", false);
      this.$store.commit("TEMPVALUE", cell.date);
      this.$store.commit("CLICKYEAR", this.tempValue.getFullYear());
      this.$store.commit("CLICKMONTH", this.tempValue.getMonth() + 1);
      this.$store.commit("CLICKDAY", this.tempValue.getDate());
      this.$store.commit("CLICKWEEK", this.tempValue.getDay());

      localStorage.setItem("chineseDay", this.chineseDay);
      localStorage.setItem("clickDay", this.clickDay);
      localStorage.setItem("clickMonth", this.clickMonth);
      localStorage.setItem("clickYear", this.clickYear);
      localStorage.setItem("clickWeek", this.clickWeek);

      this.$store.commit("ORDERTIME", "");
      this.$store.commit("ISGONEXT", false);
      this.$store.commit("ISNEXT", false);

      this.$emit("update");
    },
    // getBusinessTime() {
    //   const api = `${process.env.VUE_APP_API}/store/${this.id}/can-order-times/${this.apiDate}/${this.count}`;
    //   const vm = this;

    //   vm.$http.get(api).then((response) => {
    //     vm.businessHour = response.data.return.business_times;
    //     vm.avaliableHour = response.data.return.can_order_times;
    //   });
    // },
    isSameDay(date1, date2) {
      if (!this.isChangeMonth) {
        const [y1, m1, d1] = getYearMonthDay(date1);
        const [y2, m2, d2] = getYearMonthDay(date2);
        const [y3, m3, d3] = getYearMonthDay(this.value);
        // if (y2 === y3 && m2 === m3) {
        if (this.limitDay(date1) || (y1 === y3 && m1 === m3 && d1 === d3)) {
          return y1 === y2 && m1 === m2 && d1 === d2;
        }
      }

      // }
    },
    isToday(date) {
      const [y1, m1, d1] = getYearMonthDay(date);
      const [y2, m2, d2] = getYearMonthDay();
      return y1 === y2 && m1 === m2 && d1 === d2;
    },
    beforeToday(date) {
      const [y1, m1, d1] = getYearMonthDay(date);
      const [y2, m2, d2] = getYearMonthDay();
      return (y1 === y2 || y1 < y2) && (m1 < m2 || m1 === m2) && d1 < d2;
    },
    limitDay(date) {
      let dt = getYearMonthDay(date);
      let today = getYearMonthDay();
      let dayArrange = moment(today).add(
        this.store.order_settings.can_order_day + 1,
        "days"
      );
      return moment(dt).isBetween(today, dayArrange);
    },
    unavaliableDate(date) {
      let dt = getYearMonthDay(date);
      let today = getYearMonthDay();
      let day7 = moment(today).add(
        this.store.order_settings.can_order_day,
        "days"
      );
      return moment(dt).isAfter(day7);
    },
    getPrevMonthDays(prevLastDay, startWeek) {
      const [year, month] = getYearMonthDay(this.tempValue);
      const prevMonthDays = [];
      for (let i = prevLastDay - startWeek + 1; i <= prevLastDay; i++) {
        prevMonthDays.push({
          date: new Date(year, month - 1, i),
          status: "prev",
        });
      }
      return prevMonthDays;
    },
    getCurrentMonthDays(curLastDay) {
      const [year, month] = getYearMonthDay(this.tempValue);
      const curMonthDays = [];
      for (let i = 1; i <= curLastDay; i++) {
        curMonthDays.push({
          date: new Date(year, month, i),
          status: "current",
        });
      }
      return curMonthDays;
    },
    getNextMonthDays(curLastDay, startWeek) {
      const [year, month] = getYearMonthDay(this.tempValue);
      const nextMonthDays = [];
      for (let i = 1; i <= 42 - startWeek - curLastDay; i++) {
        nextMonthDays.push({
          date: new Date(year, month + 1, i),
          status: "next",
        });
      }
      return nextMonthDays;
    },
    getLimitDays() {
      const [year, month, day] = getYearMonthDay();
      const limitDay = [];
      // let limitDays = this.store.return.order_settings.can_order_day;
      let limitDays = day + 15;

      for (let i = day; i <= limitDays; i++) {
        limitDay.push({
          date: new Date(year, month, i),
        });
      }
      this.limitDayArray = limitDay;

      return limitDay;
    },
    filterlimitDate() {
      const newArray = [
        ...this.getDays[0],
        ...this.getDays[1],
        ...this.getDays[2],
        ...this.getDays[3],
        ...this.getDays[4],
      ];
      this.newArray = newArray;
      // let filter1 = newArray.filter(function (item) {
      //   let date1 = new Date(item.date);

      //   return date1.getTime();

      // });
      // let filter2 = this.limitDayArray.filter(function (item) {
      //   let date2 = new Date(item.date);

      //   return date2.getTime();
      // });
    },
  },
  computed: {
    clickYear() {
      return this.$store.state.clickYear;
    },
    clickMonth() {
      return this.$store.state.clickMonth;
    },
    clickDay() {
      return this.$store.state.clickDay;
    },
    clickWeek() {
      return this.$store.state.clickWeek;
    },
    value() {
      return this.$store.state.value;
    },
    tempValue() {
      return this.$store.state.tempValue;
    },
    getDays() {
      const [year, month] = getYearMonthDay(this.tempValue);
      let startWeek = new Date(year, month, 1).getDay();
      if (startWeek === 0) {
        startWeek = 7;
      }
      const prevLastDay = getPrevMonthLastDay(year, month);
      const curLastDay = getCurrentMonthLastDay(year, month);
      const days = [
        ...this.getPrevMonthDays(prevLastDay, startWeek),
        ...this.getCurrentMonthDays(curLastDay, startWeek),
        // ...this.getNextMonthDays(curLastDay, startWeek),
      ];
      return toMatrix(days, 7);
    },
    isChangeMonth() {
      return this.$store.state.isChangeMonth;
    },

    formatDate() {
      const [year, month, day] = getYearMonthDay(this.tempValue);
      localStorage.setItem("clickMonth", month + 1);
      localStorage.setItem("clickDay", day);
      localStorage.setItem("clickYear", year);
      // localStorage.setItem("clickYear", year);
      return { year, month: month + 1, day };
    },
    chineseDay() {
      let today = this.tempValue.getDay();
      if (today === 1) {
        return "一";
      } else if (today === 2) {
        return "二";
      } else if (today === 3) {
        return "三";
      } else if (today === 4) {
        return "四";
      } else if (today === 5) {
        return "五";
      } else if (today === 6) {
        return "六";
      } else if (today === 0) {
        return "日";
      }
    },
    getday() {
      let today = new Date();
      return today.getDay();
    },
    isSameMonth() {
      let currentMonth = new Date();
      const thisMonth = currentMonth.getMonth();
      const activeMonth = this.tempValue.getMonth();
      if (thisMonth === activeMonth) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    // this.getLimitDays();
    // this.unavaliableDate();
    // this.limitDay();
    this.filterlimitDate();
  },
};
</script>

<style scoped>
.calendar-width {
  width: auto;
}
.noneShow {
  display: none;
}

@media (min-width: 1024px) {
  .calendar-width {
    width: 100%;
  }
}
</style>