<template>
  <Carousel :autoplay="5000" :wrap-around="true" :transition="1000" v-if="!loading">
    <Slide v-for="image in storeInfo.store.images.banners" :key="image">
      <img :src="image" alt="" class="banner w-full" />
    </Slide>

    <template #addons>
      <Navigation class="focus:outline-none  " />
    </template>
  </Carousel>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";


export default {
  name: "CarouselBanner",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  props: {
    storeInfo: {
      type: Object,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>
<style scoped>
.banner {
  max-height: 560px;
  padding: 0px;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1024px) {
  .banner {
    height: 20vh;
  }
}

.carousel__next {
  position: absolute;
  right: 20px;
  font-size: 1rem;
  color: white;
  outline: none;
  background-color: rgba(217, 221, 234, 0.3);
  outline: none;
  border-radius: 0px;
}

.carousel__next :focus {
  outline: none;
}

.carousel__prev {
  position: absolute;
  left: 20px;
  font-size: 1rem;
  color: white;
  background-color: rgba(217, 221, 234, 0.3);
  outline: none;
  border-radius: 0px;
}
</style>