<template>
  <div class="items-center pb-5 tracking-wider bg padproHidden">
    <h5 class="pr-3 font-bold text-2xl" v-if="!loading">
      {{ store.comments.avg_score }}
    </h5>
    <span v-for="(item, index) in average_star" class="star-item mr-2" :key="index" :class="item"></span>
    <span class="text-grayColor">(</span>
    <span class="text-grayColor text-sm font-bold" v-if="!loading">
      {{ store.comments.count.total }}則</span>
    <span class="text-grayColor">)</span>
  </div>
  <div class="text-grayColor lg:border-b-2 text-xs md:text-lg lg:block">
    <div class="flex items-center mb-2 md:mb-5" v-if="store.store.types.length >0">
      <span class="mr-5">
        <img class="linkIcon" src="@/assets/image/cuisine_svg.svg" alt="" />
      </span>
      <p v-for="type in (store.store.types).join('/')" :key="type ">{{ type }}</p>
    </div>
    <div class="flex items-center mb-2 md:mb-5 " v-if="!isOpen && isBreak">
      <span class="mr-5 h-4 flex items-center">
        <span class="rounded-full h-3 w-3 md:h-5 md:w-5 bg-status-gray flex items-center justify-center"></span>
      </span>
      <p>目前休息中</p>
    </div>
    <div class="flex items-center mb-2 md:mb-5" v-if="isOpen">
      <span class="mr-5 h-4 flex items-center">
        <span class="rounded-full h-3 w-3 md:h-5 md:w-5 bg-status-green flex items-center justify-center"></span>
      </span>
      <p>目前營業中</p>
    </div>
    <div class="flex items-center mb-2 md:mb-5" v-if="!isOpen && !isBreak">
      <span class="mr-5 h-4 flex items-center">
        <span class="rounded-full h-3 w-3 md:h-5 md:w-5 bg-status-red  flex items-center justify-center"></span>
      </span>
      <p>目前已打烊</p>
    </div>
    <div class="flex items-center mb-2 md:mb-5">
      <span class="mr-5">
        <img class="linkIcon" src="@/assets/image/svg_phone.svg" alt="phoneIcon" />
      </span>
      <p class="text-main">{{ store.store.phone }}</p>
    </div>
    <div class="flex items-center mb-2 md:mb-5" v-if="store.store.website.length !== 0">
      <span class="mr-5 linkpx">
        <img class="linkIcon" src="@/assets/image/newlink.svg" alt="linkIcon" />
      </span>
      <a target="_blank" aria-label="Official website" :href="store.store.website">{{ store.store.website }}</a>
    </div>
    <div class="flex items-center mb-2 md:mb-5">
      <span class="mr-5">
        <img class="linkIcon" src="@/assets/image/svg_address.svg" alt="addressIcon" />
      </span>
      <p>{{ store.store.address }}</p>
    </div>
    <div class="flex items-center mb-2 md:mb-5">
      <span class="mr-5">
        <img class="linkIcon" src="@/assets/image/svg_hours.svg" alt="" /></span>
      <p class="pr-5 md:pr-10">本日營業</p>
      <div class="flex flex-col">
        <div v-for="time in todayBusinessHour" :key="time">
          <span>{{time.begin_time}}-</span>
          <span>{{time.end_time}}</span>
        </div>
        <p v-if="todayBusinessHour.length === 0">公休</p>
      </div>
      <button class="focus:outline-none" aria-label="Arrow Down" @click="toggleshowtime">
        <img class="px-3" src="@/assets/image/chevronDown.svg" alt="share-link" />
      </button>
    </div>
    <transition name="router-slid">
      <div v-show="showtime" class="flex justify-center">
        <div class="w-full flex items-center flex-row">
          <!-- <div class="w-3 md:w-7 h-3 mr businessTimeSpace"></div> -->
          <div class=" w-full flex flex-col  mb-5 text-xs md:text-base businessTimeSpace">
            <div class="flex leading-5 md:leading-9">
              <div class="weekSpace">週日</div>
              <template v-if="sunday.length === 0">
                <p>
                  公休
                </p>
              </template>
              <div class="tempClass">
                <div v-for="time in sunday" :key="time" class="tempClassItem">
                  <span class="text-grayColor-light ">{{time.begin_time}}-</span>
                  <span class="text-grayColor-light ">{{time.end_time}}</span>
                  <!-- <span class="text-grayColor-light " v-if="key !== Object.keys(time).length && sunday.length > 1">/</span> -->
                </div>
              </div>
            </div>
            <div class="flex  leading-5 md:leading-9">
              <div class="weekSpace">週一</div>
              <template v-if="monday.length === 0">
                <p>
                  公休
                </p>
              </template>
              <div class="tempClass">
                <div v-for="time in monday" :key="time" class="tempClassItem">
                  <span class="text-grayColor-light ">{{time.begin_time}}-</span>
                  <span class="text-grayColor-light ">{{time.end_time}}</span>
                  <!-- <span class="text-grayColor-light " v-if="key !== Object.keys(time).length && monday.length > 1">/</span> -->
                </div>
              </div>
            </div>
            <div class="flex leading-5 md:leading-9">
              <div class="weekSpace">週二</div>
              <template v-if="tuesday.length === 0">
                <p>
                  公休
                </p>
              </template>
              <div class="tempClass">
                <div v-for="time in tuesday" :key="time" class="tempClassItem">
                  <span class="text-grayColor-light ">{{time.begin_time}}-</span>
                  <span class="text-grayColor-light ">{{time.end_time}}</span>
                  <!-- <span class="text-grayColor-light " v-if="key !== Object.keys(time).length && tuesday.length > 1" >/</span> -->
                </div>
              </div>
            </div>
            <div class="flex leading-5 md:leading-9">
              <div class="weekSpace">週三</div>
              <template v-if="wednesday.length === 0">
                <p>
                  公休
                </p>
              </template>
              <div class="tempClass">
                <div v-for="time in wednesday" :key="time" class="tempClassItem">
                  <span class="text-grayColor-light ">{{time.begin_time}}-</span>
                  <span class="text-grayColor-light ">{{time.end_time}}</span>
                  <!-- <span class="text-grayColor-light " v-if="key !== Object.keys(time).length && wednesday.length > 1">/</span> -->
                </div>
              </div>
            </div>
            <div class="flex leading-5 md:leading-9">
              <div class="weekSpace">週四</div>
              <template v-if="thursday.length === 0">
                <p>
                  公休
                </p>
              </template>
              <div class="tempClass">
                <div v-for="time in thursday" :key="time" class="tempClassItem">
                  <span class="text-grayColor-light ">{{time.begin_time}}-</span>
                  <span class="text-grayColor-light ">{{time.end_time}}</span>
                  <!-- <span class="text-grayColor-light " v-if="key !== Object.keys(time).length && thursday.length > 1">/</span> -->
                </div>
              </div>
            </div>
            <div class="flex leading-5 md:leading-9">
              <div class="weekSpace">週五</div>
              <template v-if="friday.length === 0">
                <p>
                  公休
                </p>
              </template>
              <div class="tempClass">
                <div v-for="time in friday" :key="time" class="tempClassItem">
                  <span class="text-grayColor-light ">{{time.begin_time}}-</span>
                  <span class="text-grayColor-light ">{{time.end_time}}</span>
                  <!-- <span class="text-grayColor-light " v-if="key !== Object.keys(time).length && friday.length > 1">/</span> -->
                </div>
              </div>
            </div>
            <div class="flex leading-5 md:leading-9">
              <div class="weekSpace">週六</div>
              <template v-if="saturday.length === 0">
                <p>
                  公休
                </p>
              </template>
              <div class="tempClass">
                <div v-for="time in saturday" :key="time" class="tempClassItem">
                  <span class="text-grayColor-light ">{{time.begin_time}}-</span>
                  <span class="text-grayColor-light ">{{time.end_time}}</span>
                  <!-- <span class="text-grayColor-light " v-if="key != Object.keys(time).length && saturday.length > 1">/</span> -->
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import json from "../../config/env.json";

export default {
  props: {
    average_star: {
      type: Array,
    },
  },
  data() {
    return {
      ENV: json,
      showtime: false,
      newText: "",
    };
  },
  methods: {
    toggleshowtime() {
      this.showtime = !this.showtime;
    },
    trans(date, begin_time, end_time) {
      let now = moment();
      let isOpen = false;
      let bg = moment(`${date} ${begin_time}`);
      let ed = moment(`${date} ${end_time}`);

      if (ed <= bg) {
        ed.add(1, "days");
      }
      if (bg <= now && now <= ed) {
        isOpen = true;
      }
      return isOpen;
    },

  },
  computed: {
    store() {
      return this.$store.state.store;
    },
    loading() {
      return this.$store.state.loading;
    },
    monday() {
      if (
        Object.keys(this.store.business_hours).find((element) => element == 1)
      ) {
        // if(this.store.return.business_hours[1].length === 0){
        //   return '公休'
        // }
        return this.store.business_hours[1];
      }
    },
    tuesday() {
      if (
        Object.keys(this.store.business_hours).find((element) => element == 2)
      ) {
        return this.store.business_hours[2];
      }
    },
    wednesday() {
      if (
        Object.keys(this.store.business_hours).find((element) => element == 3)
      ) {
        return this.store.business_hours[3];
      }
    },
    thursday() {
      if (
        Object.keys(this.store.business_hours).find((element) => element == 4)
      ) {
        return this.store.business_hours[4];
      }
    },
    friday() {
      if (
        Object.keys(this.store.business_hours).find((element) => element == 5)
      ) {
        return this.store.business_hours[5];
      }
    },
    saturday() {
      if (
        Object.keys(this.store.business_hours).find((element) => element == 6)
      ) {
        return this.store.business_hours[6];
      }
    },
    sunday() {
      if (
        Object.keys(this.store.business_hours).find((element) => element == 0)
      ) {
        return this.store.business_hours[0];
      }
    },

    todayBusinessHour() {
      if (
        Object.keys(this.store.business_hours).find(
          (element) => element == this.getday
        )
      ) {
        return this.store.business_hours[this.getday];
      }
    },
    getday() {
      let today = new Date();
      return today.getDay();
    },
    // isOpenBK() {
    //   let length = this.todayBusinessHour.length;
    //   if (length == 0) {
    //     //公休
    //     return false;
    //   }

    //   let now = moment();
    //   let today = now.format("yyyy-MM-DD");

    //   for (let i = 0; i < length; i++) {
    //     let begin = moment(
    //       today + this.todayBusinessHour[i].begin_time,
    //       "yyyy-MM-DD HH:mm"
    //     );
    //     let end = moment(
    //       today + this.todayBusinessHour[i].end_time,
    //       "yyyy-MM-DD HH:mm"
    //     );

    //     let res = moment(now).isBetween(begin, end);
    //     if (res) {
    //       return true;
    //     }
    //   }
    //   return false;
    // },
    isOpen() {
      let businessHour = this.store.business_hours;
      let todayW = moment().weekday();
      let lastW = todayW - 1 < 0 ? 6 : todayW - 1;
      let beforeBusinessHour = businessHour[lastW];
      let todayBusinessHour = businessHour[todayW];
      let isOpen = false;

      todayBusinessHour.forEach((v) => {
        let date = moment().format("YYYY-MM-DD");
        if (this.trans(date, v.begin_time, v.end_time)) {
          isOpen = true;
          return;
        }
      });

      beforeBusinessHour.forEach((v) => {
        let date = moment().subtract(1, "days").format("YYYY-MM-DD");
        if (this.trans(date, v.begin_time, v.end_time)) {
          isOpen = true;
          return;
        }
      });

      return isOpen;
    },
    isBreak() {
      let timeLen = this.todayBusinessHour.length
      if (!this.isOpen && timeLen > 1) {
        let now = moment();
        let today = now.format("YYYY-MM-DD");
        
        for (let i = 0; i < timeLen; i++) {
          if(i == timeLen - 1){
            continue;
          }

          let begin = moment(
            today + this.todayBusinessHour[i].end_time,
            "YYYY-MM-DD HH:mm"
          );
          let end = moment(
            today + this.todayBusinessHour[i+1].begin_time,
            "YYYY-MM-DD HH:mm"
          );

          let res = moment(now).isBetween( begin,end);
          if (res) {
            return true;
          }
        }
      }
      return false;
    },
  },
  created() {
    // this.monday();
  },
};
</script>

<style scoped>
.padproHidden {
  display: none;
}

@media (min-width: 1280px) {
  .padproHidden {
    display: flex !important;
  }
}
.linkIcon {
  max-width: 13px;
}

@media (min-width: 768px) {
  .linkIcon {
    max-width: 20px;
  }
}
.linkpx {
  padding-right: 2px;
}
.businessTimeSpace {
  margin-left: 33px;
}
@media (min-width: 768px) {
  .businessTimeSpace {
    margin-left: 40px;
  }
}
.weekSpace {
  max-width: 32px;
  min-width: 32px;
  margin-right: 16px;
}
@media (min-width: 768px) {
  .weekSpace {
    margin-right: 22px;
  }
}
.tempClass {
  display: flex;
  flex-wrap: wrap;
}

.tempClass .tempClassItem:not(:last-child)::after {
  content: "／";
}
</style>