export const getYearMonthDay = (value) => {
    const date = value ? new Date(value) : new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return [year, month, day];
};

export const getCurrentMonthLastDay = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

export const getPrevMonthLastDay = (year, month) => {
    return new Date(year, month, 0).getDate();
};

export const cloneDate = (date) => {
    return new Date(date.getTime());
};